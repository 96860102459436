<script setup>
import { onMounted, reactive, ref } from "vue";
import { useUserStore } from "../../../stores/user";
import { useRoute } from "vue-router/auto";
import { setPageTitle } from "../../../utils/pageTitle";
import useInboundOrders from "../../../hooks/useInboundOrders";
import OrderHeader from "../../../components/order/OrderHeader.vue";
import InboundOrderLayout from "../../../layouts/InboundOrderLayout.vue";
import { defineEmits } from "vue";
import { shortDateWithTimeTz } from "@/utils/dateUtils";
import { useToast } from "vue-toastification";

const emit = defineEmits(["inboundOrder"]);
const {
  updatePage,
  inboundOrder,
  inboundLoading,
  orderDocuments,
  fetchInboundOrder,
  fetchInboundOrderDocuments,
  uploadInboundOrderDocuments,
  uploadingDocuments,
} = useInboundOrders();
const userStore = useUserStore();
const route = useRoute();
const toast = useToast();
const localState = reactive({
  page: parseInt(route.query.page) || 1,
  searchText: "",
  filters: [],
  sort: "id",
});
const fileInput = ref(null);

onMounted(async () => {
  await fetchInboundOrder(route.params.id).then(() => {
    setPageTitle(`Inbound Order #IN-${inboundOrder.value.id}`);
  });
  fetchInboundOrderDocuments(route.params.id, localState);
});

const uploadPhotos = async () => {
  if (fileInput.value.files.length == 0) {
    toast.error("Please select a file to upload.");
    return;
  }
  const file = fileInput.value.files[0];
  const formData = new FormData();
  formData.append("file", file, file.name);
  const res = await uploadInboundOrderDocuments(route.params.id, formData);
  if (res) {
    await fetchInboundOrderDocuments(route.params.id, localState);
  }
}

</script>

<template>
  <InboundOrderLayout :inboundOrder="inboundOrder" v-if="inboundOrder">
    <div class="w-100">
      <div v-if="userStore.user && inboundOrder" class="px-4">
        <div class="w-100 px-2">
          <div class="w-100">
            <OrderHeader
              orderType="inbound"
              :orderId="inboundOrder.id"
              :status="inboundOrder.order_status.short_label"
            />
          </div>

          <v-card class="mt-4 pa-2 w-auto" elevation="0">
            <v-card-title class="text-h5">Order Documents</v-card-title>
            <v-card-text>
              <v-table>
                <thead>
                  <tr>
                    <th class="text-left text-uppercase">ID</th>
                    <th class="text-left text-uppercase">Type</th>
                    <th class="text-left text-uppercase">BOL</th>
                    <th class="text-left text-uppercase">Created By</th>
                    <th class="text-left text-uppercase">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    colspan="2"
                    class="text-center"
                    v-if="orderDocuments?.total_count < 1"
                  >
                    <td colspan="12">No documents for this order</td>
                  </tr>
                  <tr
                    v-if="!inboundLoading && orderDocuments"
                    v-for="doc in orderDocuments.data"
                    :key="doc.id"
                  >
                    <td>
                      {{ doc.id }}
                    </td>
                    <td>{{ doc.mime_type }}</td>
                    <td>{{ doc.bol ? "Yes" : "No" }}</td>
                    <td>
                      <a :href="`/users/${doc.created_by_id}`">{{
                        doc.created_by?.name
                      }}</a>
                    </td>
                    <td>{{ shortDateWithTimeTz(doc.created_at) }}</td>
                  </tr>
                  <tr v-if="!orderDocuments">
                    <td colspan="12" class="text-center py-10">
                      <v-progress-circular
                        :size="40"
                        :width="4"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <div class="mt-2" v-if="orderDocuments">
                <v-pagination
                  v-model="localState.page"
                  :length="orderDocuments.total_pages"
                  @update:modelValue="updatePage"
                  v-if="orderDocuments.total_pages > 0"
                ></v-pagination>
              </div>
            </v-card-text>

            <v-divider class="mt-4"></v-divider>
            <v-card-actions class="pa-2">
              <v-file-input label="File input" ref="fileInput" variant="outlined" density="compact" hide-details :max-width="500"></v-file-input>
              <v-btn 
                variant="outlined"
                color="primary" 
                @click="uploadPhotos"
                density="compact"
                class="ml-4"
                :loading="uploadingDocuments"
              >
                Upload
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
  </InboundOrderLayout>
</template>
